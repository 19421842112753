import { render, staticRenderFns } from "./ApplicantDashboard.vue?vue&type=template&id=189647de&scoped=true&"
import script from "./ApplicantDashboard.vue?vue&type=script&lang=js&"
export * from "./ApplicantDashboard.vue?vue&type=script&lang=js&"
import style0 from "./ApplicantDashboard.vue?vue&type=style&index=0&id=189647de&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "189647de",
  null
  
)

export default component.exports