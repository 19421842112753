<template>
  <v-menu max-width="284" bottom offset-y nudge-right="200">
    <template #activator="{ on, attrs }">
      <svg v-on="on" v-bind="attrs" width="25" height="26" viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="9.07475" height="9.07475" fill="#008EEF"/>
        <rect y="12.9629" width="9.07475" height="9.07475" fill="#008EEF"/>
        <rect y="25.9258" width="9.07475" height="9.07475" fill="#008EEF"/>
        <rect x="12.957" width="9.07475" height="9.07475" fill="#008EEF"/>
        <rect x="12.957" y="12.9629" width="9.07475" height="9.07475" fill="#008EEF"/>
        <rect x="12.957" y="25.9258" width="9.07475" height="9.07475" fill="#008EEF"/>
        <rect x="25.9258" width="9.07475" height="9.07475" fill="#008EEF"/>
        <rect x="25.9258" y="12.9629" width="9.07475" height="9.07475" fill="#008EEF"/>
        <rect x="25.9258" y="25.9258" width="9.07475" height="9.07475" fill="#008EEF"/>
      </svg>
    </template>
    <v-card min-width="248">
      <v-card-title>
        Switch between Apps
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-row>
          <v-col v-for="(app, i) in apps" :key="i" cols="6">
            <v-card
              :class="{ 'active': isActive(app.route) }"
              max-width="100"
              :height="131"
              flat
              :href="isActive(app.route) ? undefined : app.route"
              class="d-flex px-6 flex-column justify-center align-center">
              <div class="icon d-flex align-center justify-center">
                <div>
                  <v-img
                    width="32"
                    height="23"
                    :src="app.icon"
                  />
                </div>
              </div>
              <h4 class="text-center">{{ app.name }}</h4>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-menu>

</template>

<script>
import learner from '@/assets/manage_learning.svg'
export default {
  name: 'SwitcherIcon',
  data: () => ({
    //
  }),
  computed: {
    apps () {
      const token = sessionStorage.getItem('userToken')

      // const Cookies = process.client ? require('js-cookie') : undefined
      // const token = Cookies?.get('token')
      return [
        {
          name: 'Learner',
          icon: learner,
          route: `${process.env.VUE_APP_LEARNER_FRONTEND_URL}?token=${token}`
        }
      ]
    }
  },
  methods: {
    isActive (route) {
      const host = route.split('?').shift().split('/')[2]
      console.log(host, window.location.host)
      return host === window.location.host
    }
  }
}
</script>

<style scoped lang="scss">
.icon {
  background: #008EEF;
  height: 45px;
  width: 53px;
  border-radius: 4px;
}

h4 {
  color: #001343;
  font-size: 16px;
  line-height: 28px;
  font-family: "DM Sans", serif;
}

.active {
  background: #F7FCFF;
  border: 1px solid #AAB7DB;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  border-radius: 5px;

  h4 {
    font-weight: 700;
    color: #1E323F;
  }
}
</style>
