import {mapState} from "vuex";
import {viewFile} from "@/services/utils/aws";

export default {
    data: () => ({
       image: ''
    }),
    computed: {
        ...mapState('applicantProfile', ['getApplicantProfile'])
    },
    watch: {
        getApplicantProfile: {
            handler: function (profile) {
                if (!profile.image) return
                const image = JSON.parse(profile.image).key
                viewFile({url: image, context: this})
            },
            deep: true
        }
    }
}