<template>
  <div  class=" lg:tw-hidden" v-if="$route.name !== 'OfferDetailsState' && $route.name !== 'CreateSpecializeProfile'">
    <v-bottom-navigation v-model="value" fixed color="#008EEF">
      <v-btn v-for="(data, i) in menu" :key="i" :value="data.name" :exact="data.exact" :to="data.route">
        <vue-feather :type="data.icon"></vue-feather>
      </v-btn>
    </v-bottom-navigation>
<!--    <v-btn-->
<!--        class="float-btn"-->
<!--        fab-->
<!--       color="blue"-->
<!--        @click="$route.name !== 'Jobs'? $router.push({name:'Jobs'}) : ''"-->
<!--    >-->
<!--      <vue-feather type="plus" style="color: #FFFFFF"/>-->
<!--    </v-btn>-->
  </div>

</template>

<script>
export default {
name: "BottomNavigation",
  data(){
    return{
      value:'',
    }
  },
  props: {
    menu: {
      type: Array,
      default: function () {
        return [
          {
            id: 1,
            name: 'home',
            route: '/dashboard',
            icon: 'home',
            exact: true
          },
          {
            id: 2,
            name: 'My Jobs',
            route: '/search',
            icon: 'search',
            exact: true
          },
          {
            id: 3,
            name: 'Practice',
            route: '/dashboard/practice',
            icon: 'help-circle',
            exact: true
          }, {
            id: 4,
            name: 'Profile',
            route: '/dashboard/profile',
            icon: 'users',
            exact: true
          },
        ]
      }
    }
  }

}
</script>

<style scoped>
.float-btn{
  position: fixed;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
  bottom: 0;
}

</style>