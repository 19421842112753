<template>
  <div class="mr-10 wrapper">
    <div class="profile-image">
      <div class="avatar">
        <v-avatar
            color="#EEF8FF"
            size="72">
          <img v-if="getApplicantProfile.image" :src="image"
               alt="image"
               height="72" width="72">
          <span v-else style="color: #008EEF; font-weight: bold; text-transform: capitalize">
          {{ getFirstChar(getApplicantProfile.first_name) }}{{ getFirstChar(getApplicantProfile.last_name) }}
        </span>
        </v-avatar>
      </div>
    </div>
    <div class="d-flex flex-column mt-10">
      <h4 class="name">{{ getApplicantProfile.first_name }} {{ getApplicantProfile.last_name }}</h4>
      <span class="details"><vue-feather class="mr-3" style="color: #000"
                                         type="briefcase"/>{{ getFirstArrayEl(getApplicantProfile.job_preference) }}</span>
      <span class="details"><vue-feather class="mr-3" style="color: #000"
                                         type="map-pin"/>{{ getApplicantProfile.location }}</span>
    </div>
  </div>
</template>

<script>

import {mapGetters} from 'vuex'
import profileImage from "@/mixins/profile/profileImage";

export default {
  name: "ApplicantProfile",
  mixins: [profileImage],
  props: {
    profile: Object
  },
  computed: {
    ...mapGetters('applicantProfile', ['getApplicantProfile'])
  },
  methods: {
    getFirstChar(name) {
      if (!name) return
      return name.charAt(0)
    },
    getFirstArrayEl(array) {
      if (!array) return
      return array[0]
    }
  }


}
</script>

<style scoped>
.wrapper {
  border-bottom: 1px solid #F6F6F6;
  padding-bottom: 1.5rem;
}

.profile-image {
  background: #E0E9FF;
  border-radius: 18px 18px 0px 0px;
  height: 80px;
  position: relative;
}

.name {
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0.8rem;
  color: #000000;
  text-transform: capitalize;

}

.details {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.46);
  margin-bottom: 1rem;
  display: flex;
}

.avatar {
  position: absolute;
  bottom: -24px;
  left: 10%;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

</style>