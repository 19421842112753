<template>
  <div>
    <div class="tw-flex tw-items-center tw-cursor-pointer profileHeader">
<!--      <vue-feather type="bell" class="tw-mr-3" style="color: #475661"/>-->
<!--      <AvatarIcon class="tw-hidden lg:tw-block"/>-->
<!--      &lt;!&ndash;      <span  class="name tw-mx-3 tw-hidden lg:tw-block md:tw-block">{{getFirstName[0]}}</span>&ndash;&gt;-->
<!--      <div class="tw-hidden lg:tw-block">-->
<!--        <vue-feather type="chevron-down" class="tw-ml-2" style="color: #475661"/>-->
<!--      </div>-->
<!--      <vue-feather type="bell" class="tw-mr-3" style="color: #475661"/>-->
      <v-menu left transition="scroll-x-reverse-transition" @input="sheet=true" offset-y rounded :close-on-content-click="closeOnContent">
        <template v-slot:activator="{ attrs, on }">
          <div  v-bind="attrs" v-on="on" class="tw-flex tw-items-center tw-cursor-pointer">

            <AvatarIcon v-if="!getApplicantProfile.image"/>
            <v-avatar
                v-else
                color="#EEF8FF"
                size="40">
              <img width="72" height="72"
                   :src="image"
                   alt="image" v-if="getApplicantProfile.image" >

            </v-avatar>
            <span class="name tw-mx-3 tw-hidden lg:tw-block md:tw-block">{{ getUserProfile.first_name }}</span>
            <vue-feather  v-if="$route.query.from !== 'ops'" type="chevron-down" class="tw-ml-2" style="color: #475661"/>
          </div>
        </template>
        <div v-if="$route.query.from !== 'ops'">
          <div v-if="!$vuetify.breakpoint.mobile">
            <v-list>
              <v-list-item
                  v-for="item in items"
                  :key="item.name"
                  link
              >
                <vue-feather :type="item.logo" style="color: #9CABB5;" />
                <v-list-item-content v-text="item.name" class="tw-flex pl-3 tw-w-48"
                                     @click="handleLogout(item.name)"></v-list-item-content>

              </v-list-item>
            </v-list>
          </div>
          <div v-else style="box-shadow: none">
            <v-bottom-sheet
                v-model="sheet"
                hide-overlay
                class="radCard"
            >
              <v-card class="radCard">
                <div class="tw-pl-5 tw-mt-10 tw-flex">
                  <v-avatar color="#EEF8FF"
                            size="30"
                            class="blue--text rounded-circle"
                            tile>{{
                      getUserProfile.first_name.charAt(0).toUpperCase()
                    }}{{ getUserProfile.first_name.charAt(0).toUpperCase() }}
                  </v-avatar>
                  <div class="tw-flex-wrap wrapText">
                    <small class="tw-font-">{{ getUserProfile.first_name }} {{ getUserProfile.last_name }}</small>
                    <small class="tw-pl-4 pt-n4">{{ getUserDetails.email }}</small>
                  </div>
                </div>
                <v-list>
                  <v-list-item
                      v-for="item in items"
                      :key="item.name"
                      link
                  >
                    <vue-feather :type="item.logo" style="color: #9CABB5" />
                    <v-list-item-content v-text="item.name" class="tw-flex pl-3 tw-w-48"
                                         @click="handleLogout(item.name)"></v-list-item-content>

                  </v-list-item>
                </v-list>
              </v-card>
            </v-bottom-sheet>
          </div>
        </div>

      </v-menu>
    </div>

  </div>
</template>

<script>
import AvatarIcon from "@/components/reusables/AvatarIcon";
import {logout} from "@/services/api/AuthApiService";
import profileImage from "@/mixins/profile/profileImage";
// import {viewFile} from "@/services/utils/aws";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "ProfileDropDown",
  components: {AvatarIcon},
  mixins: [profileImage],
  data() {
    return {
      sheet: false,
      closeOnContent: false,
      items: [
        {
          name: "View my Profile",
          logo: 'user'
        },
        {
          name: "Logout",
          logo: 'log-out'
        }
      ],
    }
  },
  computed: {
    ...mapGetters('applicantProfile',["getApplicantProfile"]),
    getUserDetails() {
      return JSON.parse(sessionStorage.getItem('userData'))
    },
    getUserProfile() {
      const userProfile = JSON.parse(sessionStorage.getItem('userProfile'))
      console.log(userProfile)
      if (!userProfile) return {}
      return userProfile
    },
    // getFirstName() {
    //   let name;
    //   if(this.getUserProfile){
    //     name = this.getUserProfile.first_name
    //   }
    //   return name
    // }
  },
  methods:{
    ...mapActions('applicantProfile', ['getApplicant']),
    handleLogout(type) {
      if (type === "Logout") {
        logout()
      }
      if (type === "View my Profile"){
        this.$router.push({name: "Profile"})
      }
    },
  },
  async created() {
    if(this.$route.query.email && this.$route.query.from === 'ops'){
      await this.$store.dispatch('applicantProfile/adminGetApplicant', this.$route.query.email)
    } else {
      await this.$store.dispatch('applicantProfile/getApplicant')

    }
  }
}
</script>

<style scoped>
.profileHeader {
  display: flex;
  align-items: center;
}

</style>