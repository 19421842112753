<template>
  <div class="mt-5">
    <slot name="header"></slot>
    <v-row no-gutters class="tw-p-3 lg:tw-p-14" style="margin-top: 55px">
      <v-col cols="3" class="tw-hidden lg:tw-block">
        <div style="position: fixed;" class="lg:tw-w-1/6 md:tw-w-1/6">
          <slot name="dashboard-sideNav"></slot>
        </div>
      </v-col>
      <v-col sm="12"  md="9">
        <div class=" tw-mt-6 ">
          <slot name="dashboard-content"></slot>
        </div>
      </v-col>

    </v-row>
    <BottomNavigation/>
  </div>

</template>

<script>
import BottomNavigation from "@/components/reusables/BottomNavigation";
export default {
name: "ApplicantDashboardLayout",
  components: {BottomNavigation}
}
</script>

<style scoped>
.main__content {
  min-height: calc(100vh - 64px);
}

.scroll {
  max-height: 88vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.scroll::-webkit-scrollbar {
  background: transparent !important;
  width: 5px;
  height: 8px;
}
.scroll::-webkit-scrollbar-thumb {
  background: transparent !important;
  max-height: 15px !important;
  border-radius: 6px;
}

</style>
