<template>
  <ApplicantDashboardLayout>
    <template #header>
      <Header>
<!--        <template #route-back-component>-->
<!--          <div>-->
<!--            <vue-feather type="arrow-left" @click="$router.back()"-->
<!--                         v-if="$route.name !== 'AppliedJobs' && $route.name !== 'SavedJobs'"/>-->
<!--            <small v-if="$route.name === 'AppliedJobs' || $route.name === 'SavedJobs'" class="job">Jobs</small>-->
<!--          </div>-->
<!--        </template>-->

        <template v-slot:searchBar>
          <div class="search">
            <v-text-field v-model="search" hide-details placeholder="Search"
                          solo prepend-inner-icon="mdi-magnify"
                          @keydown.enter.prevent="searchBase"/>
          </div>
        </template>
        <template v-slot:right-header-component>
          <div class='align-center tw-flex tw-gap-3.5'>
            <ProfileDropDown/>
            <SwitcherIcon/>
          </div>
        </template>
      </Header>
    </template>
    <template #dashboard-sideNav>
      <ApplicantProfile :profile="profile"/>
      <div class="tw-mt-6">
        <VerticalMenu/>
      </div>
    </template>
    <template #dashboard-content>
      <router-view :searchResult="searchResult"/>
    </template>
  </ApplicantDashboardLayout>

</template>

<script>
import Header from "@/components/reusables/Header";
import ProfileDropDown from "@/components/reusables/ProfileDropDown";
import ApplicantDashboardLayout from "@/components/layout/ApplicantDashboardLayout";
import VerticalMenu from "@/components/reusables/VerticalMenu";
import {searchJob} from "@/services/api/APIService";
import ApplicantProfile from "@/components/dashboard/ApplicantProfile";
import SwitcherIcon from "@/components/reusables/SwitcherIcon";

export default {
  name: "ApplicantDashboard",
  components: {SwitcherIcon, ApplicantProfile, VerticalMenu, ApplicantDashboardLayout, ProfileDropDown, Header},
  data: () => {
    return {
      search: "",
      profile: {},
      feedData: {},
      searchResult: []
    }
  },
  async created() {
    await this.$store.dispatch('applicantProfile/getApplicant')
  },

  methods: {
    searchBase(){
      const data = {}
      let userDetails = JSON.parse(sessionStorage.getItem('userData'))
      console.log(userDetails)
      data.email = userDetails.email
      data.search = this.search
      console.log(data)
      searchJob(data).then(res=>{
        console.log(res)
        this.searchResult = res.data
      }).catch(err=>{
        console.log(err)
      })
    }
  }


}
</script>

<style scoped>
.search {

}

.job {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: -0.02em;
  color: #1E323F;
}

</style>