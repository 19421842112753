<template>
<div>
  <v-tabs vertical hide-slider background-color="transparent">
    <v-tab class="font-weight-bold" :class="{'tab-active': $route.fullPath===menu.route}" v-for="menu in tabMenu" :key="menu.id" :to="menu.route" :exact="menu.exact">
      <vue-feather :type="menu.icon" class="mr-3 tw-ml-6"></vue-feather>
      {{menu.name}}

    </v-tab>
  </v-tabs>
</div>
</template>

<script>
export default {
  name: "VerticalMenu",
  props:{
    tabMenu: {
      type: Array,
      default: function() {
        return [
          {
            id: 1,
            name: "Home",
            route: `/dashboard`,
            exact: true,
            icon:'home'
          },
          {
            id: 2,
            name: "My Jobs",
            route: `/dashboard/jobs/applied`,
            exact: true,
            icon:'briefcase'
          },
          {
            id: 3,
            name: "Job Preferences",
            route: `/dashboard/jobPreferences`,
            exact: true,
            icon:'briefcase'
          },

          {
            id: 4,
            name: "Profile",
            route: `/profile`,
            exact: true,
            icon:'users'
          },
        ];
      }
    },

  }
}
</script>


<style scoped>
.v-tab{
  width: fit-content!important;
  transition: 0.8s ease-in !important;
  text-transform: capitalize;
  font-family: DM Sans,serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  padding-left: 0 !important;
  /*margin-left: 20px;*/

}
.v-tabs--vertical > .v-tabs-bar .v-tab{
  /*padding-left: 25px !important;*/
  padding-right: 90px !important;
  margin-top: 13px!important;
}
.tab-active {
  font-weight: bold;
  color: #008EEF !important;
  background: #F7FCFF;
  border-radius: 64px;
}
.theme--light.v-tabs .v-tab--active:hover::before, .theme--light.v-tabs .v-tab--active::before {
  opacity:0;
}


</style>